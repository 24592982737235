const SiteMGMT = SiteMGMT || {};

SiteMGMT.dashboard = {};

SiteMGMT.dashboard.update = (()=>{
    const init = () => {
        const updateNode = document.getElementById('js-lastUpdate');
        if(updateNode){
            if(updateNode.dataset.needsUpdate === "True"){
                document.getElementById('update').click();
            }
        }
    }
    return init;
})();


SiteMGMT.dashboard.chart = (()=>{

  const init = () => {
    var ctx = document.getElementById('myChart');
    if(ctx !== null){
      var myChart = new Chart(ctx, {
          type: 'bar',
          data: {
              labels: ['200s', '300s', '400s', '500s'],
              datasets: [{
                  label: '# of Errors',
                  data: [12, 19, 3, 5],
                  backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)'
                  ],
                  borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)'
                  ],
                  borderWidth: 1
              }]
          },
          options: {
              scales: {
                  yAxes: [{
                      ticks: {
                          beginAtZero: true
                      }
                  }]
              }
          }
      });
    }
  }

  return init;
})();