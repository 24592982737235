document.addEventListener("DOMContentLoaded", function(){
  // const dataTable = document.getElementById('dataTable');

  // if(dataTable){
  //   dataTable.DataTable();
  // }
  SiteMGMT.ui.confirm();
  SiteMGMT.ui.accordion();
  // SiteMGMT.dashboard.chart();
  SiteMGMT.dashboard.update();
  SiteMGMT.userFeedback.updateFeedback();
});
