const SiteMGMT = SiteMGMT || {};

SiteMGMT.ui = {};

SiteMGMT.ui.confirm = (() => {
  const init = () => {
    const buttons = Array.from(document.getElementsByClassName('js-confirm'));

    buttons.forEach((element) => {
      element.addEventListener('click', confirmIt, false);
    });
  }
  const confirmIt = (e) => {
    let confirmText = e.target.dataset.confirm;
    if(confirmText === undefined){
      confirmText = "Are you sure?";
    }
    if (!confirm(confirmText)) e.preventDefault();
  }
  return init;
})();

SiteMGMT.ui.accordion = (() => {
  function activate(drawer) {
    drawer.find('.accordion-title').addClass('is-active').attr('aria-expanded', true);
    drawer.find('.accordion-content, .drawer-header__arrow').addClass('is-active');
  }

  function deactivate(drawer) {
    drawer.find('.accordion-title').removeClass('is-active').attr('aria-expanded', false);
    drawer.find('.accordion-content, .drawer-header__arrow').removeClass('is-active');
  }

  function toggleDrawer(drawer) {
    if (drawer.find('.accordion-title').attr('aria-expanded') === 'true') {
      deactivate(drawer);
    } else if (drawer.find('.accordion-title').attr('aria-expanded') === 'false') {
      activate(drawer);
    } else {
      activate(drawer);
    }
  }

  const init = () => {
    let activeDrawer = $('.accordion-panel:first');
    if (window.location.hash) {
      const id = window.location.hash.substr(1);
      activeDrawer = $(document.getElementById(id)).parents('.accordion-panel');
    }
    toggleDrawer(activeDrawer);

    $('.accordion-panel .accordion-title').on('click', (e) => {
      const clickedDrawer = $(e.target).parents('.accordion-panel');
      toggleDrawer(clickedDrawer);
    });
    $('.accordion-panel .accordion-title').on('keydown', (e) => {
      if (e.keyCode === 32 || e.keyCode === 13) {
        e.preventDefault();
        const clickedDrawer = $(e.target).parents('.accordion-panel');
        toggleDrawer(clickedDrawer);
      }
    });
  };

  return init;
})();