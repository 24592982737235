var SiteMGMT = SiteMGMT || {};

SiteMGMT.userFeedback = {};

SiteMGMT.userFeedback.updateFeedback = (() => {
    // Dom object
    let feedbackDisplay = null;
    // State of unfinished logs
    let complete = null;

    const getFriendlyMessage = (status, message) => {
        var messageMap = {
            running : {
                'individual_create': 'Creating your site',
                'site_specifics': 'Updating site information',
                'themes_plugins_users': 'Updating theme, plugin and user information',
                'individual_archive': 'Archiving site',
                'individual_fix_500': 'Attempting site repair',
                'individual_restore_archive': 'Restoring archive',
                'update_site': 'Updating site information',
                'activate_deactivate_plugins_theme': 'Activating/deactivating plugins/theme',
                'default': 'Task Running'
            },
            complete : {
                'individual_create': 'Site creation complete',
                'site_specifics': 'Site information updated',
                'themes_plugins_users': 'Theme, plugin and user information has been updated',
                'individual_archive': 'Site archive complete',
                'individual_fix_500': 'Site repair complete',
                'individual_restore_archive': 'Site restore complete',
                'update_site': 'Site information updated',
                'activate_deactivate_plugins_theme': 'Plugins/theme changed',
                'default': 'Task Complete'
            },
            error : {
                'individual_create': 'Site creation failed',
                'site_specifics': 'Failed to update site information',
                'themes_plugins_users': 'Failed to update theme, plugin and user information',
                'individual_archive': 'Failed to archive site',
                'individual_fix_500': 'Site repair failed',
                'individual_restore_archive': 'Site restore failed',
                'update_site': 'Failed to update site information',
                'activate_deactivate_plugins_theme': 'Failed to activate/deactivate plugins/theme',
                'default': 'Task failed'
            }
        }
        return messageMap[status][message] || messageMap[status]['default'];
    }


    const getRunningTime = (createdDate) =>{
        const startTime = new Date(createdDate).getTime();
        const now = new Date().getTime();
        const ms = now - startTime;
        const hr = Math.floor(ms/1000/60/60);
        const minutes = Math.floor((ms - hr*60*60*1000)/1000/60);
        const seconds = Math.floor((ms - hr*60*60*1000 - minutes*60*1000)/1000);

        return  `${hr}:${minutes}:${seconds}`;
    }

    const createAlert = (alertType, alertText, itemNumber, alertDismiss = false) => {
        let alert = `<div class="alert alert-${alertType} alert-dismissible margin-2" data-alert="${itemNumber}" role="alert"><p ${(alertDismiss)  ? '' : 'class="loading-elipsis"'}>${alertText}</p>`;

        if(alertDismiss){
            alert += `
                <button type="button" class="close js-close-alert" data-alert="${itemNumber}" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            `;
        }
        alert += `</div>`;
        return alert;
    }

    const updateFeedbackDisplay = (response) => {
        // Clear out the alerts
        feedbackDisplay.innerHTML = '';
        // Set to true unless it hits the else below
        complete = true;
        response.forEach((log, index) => {
            if(log.fields.status === 'successful'){
                // Just reload if updating site specifics
                if(log.fields.job_key === 'update_site'){
                    setTimeout(()=>{
                        window.location.reload();
                    }, 1000)
                } else {
                    const alertText = getFriendlyMessage('complete', log.fields.job_key);
                    const alert = createAlert('info', alertText, index, true)
                    feedbackDisplay.innerHTML += alert;
                }
            } else if(log.fields.status === 'error' || log.fields.status === 'failed'){
                const alertText = getFriendlyMessage('error', log.fields.job_key);
                const alert = createAlert('error', alertText, index, true)
                feedbackDisplay.innerHTML += alert;
            } else {
                const alertText = getFriendlyMessage('running', log.fields.job_key);
                const alert = createAlert('info', alertText, index)
                feedbackDisplay.innerHTML += alert;
                // Set complete to false
                complete = false;
            }
        });

        const buttons = document.getElementsByClassName('js-close-alert');
        for(var i = 0; i < buttons.length; i++){
            buttons[i].addEventListener('click', (e)=>{
                feedbackDisplay.removeChild(e.target.parentNode.parentNode);
            })
        }
    }

    const getLogStatus = (scrf, logIds) => {
        var myHeaders = new Headers();
        myHeaders.append('X-CSRFToken', scrf);
        const object = {
            logIds: logIds
        };
        const request = new Request(`/sites/logs-json`, {
            method: 'POST',
            body: JSON.stringify(object),
            headers: myHeaders
        });

        fetch(request)
            .then(response => {
                if (response.status === 404) {
                    // Reload the page, they lost perms
                    location.reload();
                    
                }
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong on api server!');
                }
            })
            .then(response => {
                updateFeedbackDisplay(response);
            }).catch(error => {
                console.error('error', error);
            });
    }
    const showDashboardButtons = () => {
        const dashboardButtons = document.getElementsByClassName('js-dashboard-button');
        for(var i = 0; i < dashboardButtons.length; i++){
            dashboardButtons[i].classList.remove('hidden');
        }
        const placeHolderButtons = document.getElementsByClassName('js-placeholder-button');
        for(var i = 0; i < dashboardButtons.length; i++){
            placeHolderButtons[i].classList.add('hidden');
        }
    }

    const init = () => {
        feedbackDisplay = document.getElementById('js-alert');
        if (feedbackDisplay) {
            const logIds = (feedbackDisplay.dataset.logIds !== '[]') ? JSON.parse(feedbackDisplay.dataset.logIds) : [];
            const scrf = feedbackDisplay.dataset.csrf;
            // If we have logIds then we should show the alert let the user dismiss
            if (logIds.length > 0) {
                // show the alert
                feedbackDisplay.classList.remove('hidden');
                getLogStatus(scrf, logIds);
                const interval = setInterval(function () {
                    if (complete) {
                        clearInterval(interval);
                        // show the dashboard button
                        showDashboardButtons()
                    } else {
                        getLogStatus(scrf, logIds);
                    }
                }, 4000)
            } else {
                // Show the dashboard buttons if there are no logs reported on load
                showDashboardButtons()
            }
        }
    };

    return init;
})();
